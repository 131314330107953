var exports = {};
function _objectWithoutPropertiesLoose(r, e) {
  if (null == r) return {};
  var t = {};
  for (var n in r) if ({}.hasOwnProperty.call(r, n)) {
    if (e.includes(n)) continue;
    t[n] = r[n];
  }
  return t;
}
exports = _objectWithoutPropertiesLoose, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;